<template>
  <div class="info">
    <div class="info__photo">
      <img :src="iconUser" />
    </div>
    <div class="info__inner">
      <div class="title">
        <span class="name">{{ currentAccount | formatAccount }}</span>
        <a :href="getBlockExplorerPathByCode(chain, `/address/${currentAccount}`)" class="creator">
          <img :src="SOL.icon" />
          <span>Balance: {{ SOL.balance }}</span>
        </a>
      </div>
      <div class="contract">
        <span>{{ currentAccount }}</span>
        <div class="icon g-clickable" @click="copy(currentAccount)">
          <svg viewBox="0 0 15.0033 15.0033">
            <g
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M4.835,4.835 L4.835,2.96833 C4.835,2.22159 4.835,1.84822 4.98032,1.56301 C5.10815,1.31213 5.31213,1.10815 5.56301,0.98032 C5.84822,0.835 6.22159,0.835 6.96833,0.835 L12.035,0.835 C12.7817,0.835 13.1551,0.835 13.4403,0.98032 C13.6912,1.10815 13.8952,1.31213 14.023,1.56301 C14.1683,1.84822 14.1683,2.22159 14.1683,2.96833 L14.1683,8.035 C14.1683,8.78173 14.1683,9.1551 14.023,9.44032 C13.8952,9.6912 13.6912,9.8952 13.4403,10.023 C13.1551,10.1683 12.7817,10.1683 12.035,10.1683 L10.1683,10.1683"
                id="Path"
                stroke="currentColor"
                stroke-width="1.67"
                fill-rule="nonzero"
              ></path>
              <path
                d="M2.96833,14.1683 L8.035,14.1683 C8.78173,14.1683 9.1551,14.1683 9.44032,14.023 C9.6912,13.8952 9.8952,13.6912 10.023,13.4403 C10.1683,13.1551 10.1683,12.7817 10.1683,12.035 L10.1683,6.96833 C10.1683,6.22159 10.1683,5.84822 10.023,5.56301 C9.8952,5.31213 9.6912,5.10815 9.44032,4.98032 C9.1551,4.835 8.78173,4.835 8.035,4.835 L2.96833,4.835 C2.22159,4.835 1.84822,4.835 1.56301,4.98032 C1.31213,5.10815 1.10815,5.31213 0.98032,5.56301 C0.835,5.84822 0.835,6.22159 0.835,6.96833 L0.835,12.035 C0.835,12.7817 0.835,13.1551 0.98032,13.4403 C1.10815,13.6912 1.31213,13.8952 1.56301,14.023 C1.84822,14.1683 2.22159,14.1683 2.96833,14.1683 Z"
                id="Path"
                stroke="currentColor"
                stroke-width="1.67"
                fill-rule="nonzero"
              ></path>
            </g>
          </svg>
        </div>
        <a
          class="icon g-clickable"
          :href="`https://solscan.io/account/${currentAccount}`"
          target="_blank"
        >
          <svg viewBox="0 0 10 10">
            <path
              d="M4 2V3H1.5V8.5H7V6H8V9C8 9.13261 7.94732 9.25979 7.85355 9.35355C7.75979 9.44732 7.63261 9.5 7.5 9.5H1C0.867392 9.5 0.740215 9.44732 0.646447 9.35355C0.552678 9.25979 0.5 9.13261 0.5 9V2.5C0.5 2.36739 0.552678 2.24021 0.646447 2.14645C0.740215 2.05268 0.867392 2 1 2H4ZM9.5 0.5V4.5H8.5V2.2065L4.6035 6.1035L3.8965 5.3965L7.7925 1.5H5.5V0.5H9.5Z"
              fill="currentColor"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getSolanaBalances } from "@/init";
import iconSOL from "@/assets/token/icon-sol.svg";
import iconUser from "@/assets/images/icon-user.png";
import { getBlockExplorerPathByCode } from "@/utils/chain";

export default {
  data() {
    return {
      iconUser,
      SOL: {
        symbol: "SOL",
        address: "So11111111111111111111111111111111111111112",
        decimals: 9,
        icon: iconSOL,
        balance: 0,
        amount: "",
      },
      getBlockExplorerPathByCode
    };
  },
  watch: {
    currentAccount() {
      this.getBalance();
    },
  },
  methods: {
    async getBalance() {
      if (!this.currentAccount) return;
      await getSolanaBalances([this.SOL], this.currentAccount);
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 120px 1fr;
  margin-bottom: 20px;

  &__photo {
    .img {
      width: 120px;
      height: 120px;
    }
    img {
      width: 120px;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  &__inner {
    .title {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .txt {
        height: 18px;
        width: 200px;
      }
      .name {
        font-size: 18px;
        font-weight: 600;
        color: var(--color);
      }
      .sns {
        margin-left: 10px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        a {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 16px;
            color: var(--color-content);
          }

          &:hover {
            svg {
              color: var(--color);
            }
          }
        }
      }
      .creator {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
          font-size: 14px;
          color: var(--color-content);
        }
        .img {
          height: 18px;
          width: 120px;
          margin-left: 6px;
        }
        img {
          height: 16px;
          margin: 0 6px;
        }
        a {
          font-size: 16px;
          color: var(--color);
        }
      }
    }
    .contract {
      height: 24px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .txt {
        height: 14px;
        width: 240px;
      }
      span {
        font-size: 14px;
        color: var(--color);
      }
      .icon {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
        svg {
          width: 14px;
          color: var(--color-content);
        }
        &:hover {
          svg {
            color: var(--color);
          }
        }
      }
    }
    .desc {
      .txt {
        height: 40px;
        width: 420px;
        display: block;
      }
      span {
        font-size: 14px;
        line-height: 1.6;
        color: var(--color-content);
      }
    }
  }

  &__stats {
    padding-top: 20px;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--border-color);

    .item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      .txt {
        height: 16px;
        width: 90px;
      }

      span {
        display: block;
        &:first-child {
          line-height: 1;
          font-size: 14px;
          color: var(--color-des);
          margin-bottom: 20px;
        }
        &:last-child {
          line-height: 1;
          font-size: 16px;
          font-weight: 600;
          color: var(--color);
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: -24px;
        bottom: -20px;
        right: 0;
        width: 1px;
        background: linear-gradient(var(--color) 0%, var(--border-color) 100%);
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>