<template>
	<div class="connect-type">
		<template v-if="accountType === BullMe">
			<div class="switch-btn" @click="() => toggleAccountType(Wallet)">Switch to Wallet Account</div>
		</template>
		<template v-if="accountType === Wallet">
			<div class="switch-btn" @click="() => toggleAccountType(BullMe)">Switch to BullMe Account</div>
		</template>
		<Waiting v-if="waiting" :text="`Switch to ${accountType} Account.`"/>
	</div>
</template>

<script>
import { debounce } from "@/utils/helper";
import Waiting from "./ConnectWaiting.vue";
import store from "@/store";
import { BullMe, Wallet } from '@/constants/account';

export default {
	data() {
		return {
			BullMe,
			Wallet,
			waiting: false
		};
	},
	components: { Waiting },
	methods: {
		toggleAccountType: debounce(function (accountType) {
			this.waiting = true;
			store.commit('SET_ACCOUNT_TYPE', accountType);
			setTimeout(() => {
				location.reload()
			}, 300)
		})
	}
};
</script>

<style lang="scss" scoped>
.connect-type {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;

	.switch-btn {
		cursor: pointer;
		height: 48px;
		text-align: center;
		padding: 0 12px;
		border-radius: 8px;
		transition: all 0.3s ease;
		background: var(--color-yellow);
		color: #000;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		font-size: 18px;
	}
}
</style>