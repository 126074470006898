<template>
  <NouDialog :visible.sync="visible" @click.native="closeModal" @leave="closeModal" :dialogClass="{ wallet: true }">
    <div class="login">
      <div class="login__title">
        <span>Login</span>
        <div class="login__close g-clickable" @click="closeModal">
          <i class="iconfont icon-close nou-dialog__close nou-g-btn--hover" />
        </div>
      </div>
      <div class="login__tips">
        <span>The email address of your social account determines your Bull
          account. Changing your email will result in a different Bull
          account.</span>
      </div>
      <div class="login__body">
        <div class="login__list g-scrollbar">
          <div class="g-clickable login__item google">
            <svg viewBox="0 0 1024 1024" class="icon">
              <path d="M214.101333 512c0-32.512 5.546667-63.701333 15.36-92.928L57.173333 290.218667A491.861333 491.861333 0 0 0 4.693333 512c0 79.701333 18.858667 154.88 52.394667 221.610667l172.202667-129.066667A290.56 290.56 0 0 1 214.101333 512" fill="#FBBC05"></path>
              <path d="M516.693333 216.192c72.106667 0 137.258667 25.002667 188.458667 65.962667L854.101333 136.533333C763.349333 59.178667 646.997333 11.392 516.693333 11.392c-202.325333 0-376.234667 113.28-459.52 278.826667l172.373334 128.853333c39.68-118.016 152.832-202.88 287.146666-202.88" fill="#EA4335"></path>
              <path d="M516.693333 807.808c-134.357333 0-247.509333-84.864-287.232-202.88l-172.288 128.853333c83.242667 165.546667 257.152 278.826667 459.52 278.826667 124.842667 0 244.053333-43.392 333.568-124.757333l-163.584-123.818667c-46.122667 28.458667-104.234667 43.776-170.026666 43.776" fill="#34A853"></path>
              <path d="M1005.397333 512c0-29.568-4.693333-61.44-11.648-91.008H516.650667V614.4h274.602666c-13.696 65.962667-51.072 116.650667-104.533333 149.632l163.541333 123.818667c93.994667-85.418667 155.136-212.650667 155.136-375.850667" fill="#4285F4"></path>
            </svg>
            <div class="name">
              <span>Continue with Google</span>
            </div>
            <div id="google"></div>
          </div>
          <div class="group" v-if="false">
            <div class="g-clickable login__item">
              <img src="@/assets/images/icon-x.svg" class="icon" />
              <div class="name">
                <span>X (Twitter)</span>
              </div>
            </div>
            <div class="g-clickable login__item">
              <img src="@/assets/images/icon-discord.svg" class="icon" />
              <div class="name">
                <span>Discord</span>
              </div>
            </div>
          </div>
		  	<template v-if="showWeb3Login">
				<div class="split">
					<span>Login via Web3</span>
				</div>
				<div class="group">
					<div class="g-clickable login__item" v-for="(item, i) in list" :key="i" @click="connect(item)">
					<img :src="item.icon" class="icon" />
					<div class="name">
						<span>{{ item.name }}</span>
					</div>
					</div>
				</div>
			</template>
        </div>
      </div>
    </div>
  </NouDialog>
</template>

<script>
import NouDialog from "@/components/common/NouDialog";
import { WalletList } from "@/utils/connect";
import { tryConnect } from "@/init";
import { socialLogin } from "@/api/account";
import store from "@/store";
import { Google } from '@/constants/account';
import { isMobileBrowser, getAppWalletCode } from "@/utils/helper";

export default {
  components: {
    NouDialog,
  },
  data () {
    return {
      code: "",
      visible: true,
    };
  },
  computed: {
    list () {
      // let source = this.$route.params.source;
      // if (!source) {
      //   if (this.chainCode === "solana") {
      //     source = "pump";
      //   } else {
      //     source = "four";
      //   }
      // }
      // return WalletList.filter((item) => {
      //   return item.source.indexOf(source) >= 0;
      // });
      return WalletList;
    },
	showWeb3Login(){
		if (isMobileBrowser()) {
			return !!getAppWalletCode()
		} else {
			return true;
		}
	}
  },
  mounted () {
    this.google();
  },
  methods: {
    
    google () {
      window.handleCredentialResponse = async (response) => {
        // const userObject = jwt_decode(response.credential);
        console.log("Encoded JWT ID token: " + JSON.stringify(response));
        const { credential } = response;
        const res = await socialLogin(credential);
        console.log('handleCredentialResponse', res, res.data);
        if (res) {
          const { token, email, wallet, address } = res.data || {};
          store.commit("SET_CHAINCODE", "solana");
          store.commit("SET_WALLET", "google");
          store.commit('SET_ACCOUNT', wallet || email || address);
          store.commit('SET_AUTH_TOKEN', token);
          store.commit('SET_AUTH_EMAIL', email);
          store.commit('SET_AUTH_WALLET', wallet);
          store.commit('SET_AUTH_ADDRESS', address);
          store.commit('SET_ACCOUNT_TYPE', Google);
          this.closeModal();
        }
      };
      google.accounts.id.initialize({
        client_id:
          "458096766644-5injuebvkeagaak8g1skc1g7ic27i979.apps.googleusercontent.com",
        callback: handleCredentialResponse,
      });
      google.accounts.id.renderButton(
        document.getElementById("google"),
        { theme: "outline" }
      );
    },
    closeModal () {
      this.$emit("close");
    },
    async connect (item) {
      const { code, connect } = item;
      this.code = code;
      const account = await tryConnect(code, connect);
      this.code = "";
      if (account) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 18px;
      font-weight: 600;
    }

    padding: 16px 20px 16px 20px;
    margin: -20px -20px 0;
  }

  &__close {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--border-color);

    i {
      font-size: 18px;
      font-weight: 600;
      color: var(--color-content);
      transition: all 0.3s ease;
    }

    &:hover {
      i {
        color: var(--color);
      }
    }
  }

  &__tips {
    padding: 10px 0 15px;

    span {
      font-size: 14px;
      line-height: 1.25;
      color: var(--color-content);
    }
  }

  &__list {
    overflow: auto;

    .group {
      margin-top: 15px;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr;
    }

    .split {
      padding-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      span {
        font-size: 14px;
        color: var(--color);
        background: var(--card);
        position: relative;
        display: block;
        padding: 5px 15px;
        z-index: 2;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        background: var(--border-color);
        z-index: 1;
      }
    }
  }

  &__item {
    cursor: pointer;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    grid-template-columns: 35px 1fr;
    padding: 0 25px;
    border: 1px solid var(--field);
    border-radius: 24px;
    transition: all 0.3s ease;


    &.google {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .icon {
        width: 25px;
        margin-right: 2px;
      }

      #google {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        opacity: 0;
      }
    }

    &:last-child {
      margin-bottom: 10px;
    }

    &:hover {
      background-color: var(--field);
    }

    .icon {
      width: 30px;
      border-radius: 15px;
    }

    .name {
      color: var(--color);
      font-size: 14px;
      font-weight: 600;
    }

    .padding {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-des);
      }
    }
  }
}
</style>
