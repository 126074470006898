import { render, staticRenderFns } from "./SoarEmpty.vue?vue&type=template&id=5374b386&scoped=true"
import script from "./SoarEmpty.vue?vue&type=script&lang=js"
export * from "./SoarEmpty.vue?vue&type=script&lang=js"
import style0 from "./SoarEmpty.vue?vue&type=style&index=0&id=5374b386&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5374b386",
  null
  
)

export default component.exports