import { mapState, mapGetters } from "vuex";
import copy from "copy-to-clipboard";
import showToast from "@/components/toast";

export default {
  computed: {
    ...mapState({
      account: (state) => state.account,
      solBalance: (state) => state.solBalance,
      chainCode: (state) => state.chainCode,
      chainId: (state) => state.chainId,
      authAddress: (state) => state.authAddress,
      authSolBalance: (state) => state.authSolBalance,
	  accountType: (state) => state.accountType,
    }),
	...mapGetters(["currentAccount", "currentSolBalance"])
  },
  data () {
    return {
      iconDefault: require("@/assets/images/moonshot.webp"),
      solanaMinGas: 0.0013,
    };
  },
  methods: {
    copy (mint, msg) {
      copy(mint);
      if (msg) {
        showToast(msg);
      } else {
        showToast("Copy success.");
      }
    },
  },
};
