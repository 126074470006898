<template>

	<transition name="fadeBack">
		<div class="nou-dialog nou-g-box ">
			<div class="nou-dialog__container__wrapper">
				<transition name="fade">
					<div class="nou-dialog__container">
						<div class="g-dialog-panel loading">
							<div class="content">
								<div class="message loading">
									<div class="icon">
										<svg version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg"
											xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100"
											enable-background="new 0 0 0 0" xml:space="preserve">
											<circle fill="none" stroke="var(--color-des)" stroke-width="3" cx="50"
												cy="50" r="44" style="opacity: 0.5"></circle>
											<circle fill="var(--color)" stroke="var(--card)" stroke-width="3" cx="7"
												cy="55" r="6">
												<animateTransform attributeName="transform" dur="0.4s" type="rotate"
													from="0 50 48" to="360 50 52" repeatCount="indefinite">
												</animateTransform>
											</circle>
										</svg>
									</div>
									<span class="text">{{ text }}</span>
								</div>
							</div>
						</div>
					</div>
				</transition>
			</div>
		</div>
	</transition>
</template>

<script>

export default {
	props: {
		text: {
			type: String,
			default: 'loading',
		},
	},
};
</script>

<style lang="scss" scoped>
.nou-dialog {
	z-index: 9999;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: var(--dialog-mask);
	cursor: initial;
	overflow: auto;
}

.nou-dialog__container__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100%;
}

.nou-dialog__container {
	position: relative;
	margin: 100px 0;
	width: 360px; // 400 total
	max-width: 100%;
	padding: 20px;
	border-radius: 16px;
	background: var(--dialog-content);
	box-shadow: 0 2px 12px rgba(0, 0, 0, .03), 0 20px 70px rgba(0, 0, 0, .06), 0 2px 4px rgba(0, 0, 0, .02);
	text-align: left;

	@media (max-width: 750px) {
		width: calc(100% - 20px);
		box-sizing: border-box;
		margin: 0 10px;
	}

	@media (max-width: var(--max-width)) {
		margin: 1rem 0;
		box-sizing: border-box;
		width: calc(100% - 0.6rem);
		padding: 0.4rem;
	}
}

.fadeBack-enter-active,
.fadeBack-leave-active {
	transition: opacity 0.5s;
	opacity: 1;
}

.fadeBack-enter,
.fadeBack-leave-active {
	opacity: 0;
}


.icon {
	margin: 50px auto;

	svg {
		width: 100px;
	}
}

.message {
	display: flex;
	align-content: center;
	flex-direction: column;

	.text {
		font-size: 16px;
		font-weight: 500;
		line-height: 1.25;
		color: var(--color);
		margin-bottom: 15px;
		text-align: center;
	}

	&.loading {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
}

@keyframes circle {
	from {
		stroke-dashoffset: 1194;
	}

	to {
		stroke-dashoffset: 2388;
	}
}
</style>
