<template>
  <NouDialog>
    <div class="deposit">
      <div class="deposit__title">
        <span>Deposit</span>
        <div class="icon g-clickable" @click="close">
          <i class="iconfont icon-close" />
        </div>
      </div>
      <div class="deposit__content">
        <div class="qrcode">
          <img :src="dataUrl" />
        </div>
        <div class="address">
          <span>{{ currentAccount }}</span>
          <div class="icon g-clickable" @click="copy(currentAccount)">
            <svg viewBox="0 0 15.0033 15.0033">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                <path
                  d="M4.835,4.835 L4.835,2.96833 C4.835,2.22159 4.835,1.84822 4.98032,1.56301 C5.10815,1.31213 5.31213,1.10815 5.56301,0.98032 C5.84822,0.835 6.22159,0.835 6.96833,0.835 L12.035,0.835 C12.7817,0.835 13.1551,0.835 13.4403,0.98032 C13.6912,1.10815 13.8952,1.31213 14.023,1.56301 C14.1683,1.84822 14.1683,2.22159 14.1683,2.96833 L14.1683,8.035 C14.1683,8.78173 14.1683,9.1551 14.023,9.44032 C13.8952,9.6912 13.6912,9.8952 13.4403,10.023 C13.1551,10.1683 12.7817,10.1683 12.035,10.1683 L10.1683,10.1683"
                  id="Path" stroke="currentColor" stroke-width="1.67" fill-rule="nonzero"></path>
                <path
                  d="M2.96833,14.1683 L8.035,14.1683 C8.78173,14.1683 9.1551,14.1683 9.44032,14.023 C9.6912,13.8952 9.8952,13.6912 10.023,13.4403 C10.1683,13.1551 10.1683,12.7817 10.1683,12.035 L10.1683,6.96833 C10.1683,6.22159 10.1683,5.84822 10.023,5.56301 C9.8952,5.31213 9.6912,5.10815 9.44032,4.98032 C9.1551,4.835 8.78173,4.835 8.035,4.835 L2.96833,4.835 C2.22159,4.835 1.84822,4.835 1.56301,4.98032 C1.31213,5.10815 1.10815,5.31213 0.98032,5.56301 C0.835,5.84822 0.835,6.22159 0.835,6.96833 L0.835,12.035 C0.835,12.7817 0.835,13.1551 0.98032,13.4403 C1.10815,13.6912 1.31213,13.8952 1.56301,14.023 C1.84822,14.1683 2.22159,14.1683 2.96833,14.1683 Z"
                  id="Path" stroke="currentColor" stroke-width="1.67" fill-rule="nonzero"></path>
              </g>
            </svg>
          </div>
        </div>
        <div class="balance auth">
          <div class="icon">
            <img src="@/assets/token/icon-sol.png" />
          </div>
          <span>SOL</span>
          <div class="input">
            <input type="text" v-model="authSolBalance" readonly />
          </div>
          <div class="button g-clickable" @click="refresh">
            <svg viewBox="0 0 19 19" id="arrow_loading">
              <path stroke="none" fill="rgba(255,255,255,0.05)"
                d="M17.61907,6.64429064 L13.8617197,6.64429064 C13.3840439,6.64429064 12.9914138,6.25155686 12.9914138,5.77398471 C12.9914138,5.2963089 13.3840439,4.90357512 13.8617197,4.90357512 L15.3689095,4.90357512 C14.0103035,2.98248087 11.7707673,1.73006538 9.2340637,1.73006538 C5.0840575,1.73006538 1.7194412,5.09468172 1.7194412,9.24468791 C1.7194412,13.3946681 5.0840575,16.7592845 9.2340637,16.7592845 C13.3840439,16.7592845 16.74866,13.3946681 16.74866,9.24468791 C16.74866,8.76701211 17.141394,8.37438191 17.61907,8.37438191 C18.096616,8.37438191 18.48935,8.76701211 18.48935,9.24468791 C18.48935,14.3498902 14.3498902,18.4893498 9.2446879,18.4893498 C4.139356,18.4893498 0,14.3498902 0,9.24468791 C0,4.13935601 4.139356,0 9.2446879,0 C12.3438798,0 15.0823402,1.52833455 16.759284,3.86343695 L16.759284,2.31382802 C16.759284,1.83615222 17.152018,1.44352209 17.62959,1.44352209 C18.10724,1.44352209 18.5,1.83615222 18.5,2.31382802 L18.5,5.78450529 C18.478726,6.26218109 18.096616,6.64429064 17.61907,6.64429064 Z">
              </path>
              <defs>
                <path id="arrow" stroke="none" fill="none"
                  d="M17.61907,6.64429064 L13.8617197,6.64429064 C13.3840439,6.64429064 12.9914138,6.25155686 12.9914138,5.77398471 C12.9914138,5.2963089 13.3840439,4.90357512 13.8617197,4.90357512 L15.3689095,4.90357512 C14.0103035,2.98248087 11.7707673,1.73006538 9.2340637,1.73006538 C5.0840575,1.73006538 1.7194412,5.09468172 1.7194412,9.24468791 C1.7194412,13.3946681 5.0840575,16.7592845 9.2340637,16.7592845 C13.3840439,16.7592845 16.74866,13.3946681 16.74866,9.24468791 C16.74866,8.76701211 17.141394,8.37438191 17.61907,8.37438191 C18.096616,8.37438191 18.48935,8.76701211 18.48935,9.24468791 C18.48935,14.3498902 14.3498902,18.4893498 9.2446879,18.4893498 C4.139356,18.4893498 0,14.3498902 0,9.24468791 C0,4.13935601 4.139356,0 9.2446879,0 C12.3438798,0 15.0823402,1.52833455 16.759284,3.86343695 L16.759284,2.31382802 C16.759284,1.83615222 17.152018,1.44352209 17.62959,1.44352209 C18.10724,1.44352209 18.5,1.83615222 18.5,2.31382802 L18.5,5.78450529 C18.478726,6.26218109 18.096616,6.64429064 17.61907,6.64429064 Z">
                </path>
                <clipPath id="arrow-clip">
                  <use xlink:href="#arrow"></use>
                </clipPath>
              </defs>
              <g clip-path="url(#arrow-clip)">
                <circle cx="7.02" cy="7.02" r="4.69" transform="rotate(365,12,12)" fill="none" stroke="currentColor" stroke-width="16" stroke-dasharray="30" stroke-dashoffset="0">
                  <animate attributeName="stroke-dashoffset" values="0;-30" begin="arrow_loading.click; 0.7s" repeatCount="indefinite" dur="9.3s"></animate>
                </circle>
              </g>
              <use xlink:href="#arrow"></use>
              <animateTransform id="transform_0" attributeName="transform" attributeType="XML" type="rotate" from="0 0 0" to="-10 0 0" dur="0.07s" begin="arrow_loading.click;" repeatCount="1"></animateTransform>
              <animateTransform id="transform_1" attributeName="transform" attributeType="XML" type="rotate" from="-45 0 0" to="390 0 0" dur="0.6s" begin="transform_0.end" repeatCount="1"></animateTransform>
              <animateTransform id="transform_2" attributeName="transform" attributeType="XML" type="rotate" from="390 0 0" to="360 0 0" dur="0.15s" begin="transform_1.end" repeatCount="1"></animateTransform>
            </svg>
            <span>Refresh</span>
          </div>
        </div>
        <div class="wallet" :class="{ fold: fold === true }" v-if="wallet !== 'google'">
          <div class="wallet__title" @click="fold = !fold">
            <svg viewBox="0 0 1024 1024">
              <path
                d="M704.682667 128C851.797333 128 938.666667 212.693333 938.666667 357.632h-180.522667v1.450667c-83.797333 0-151.722667 66.218667-151.722667 147.925333s67.925333 147.925333 151.722667 147.925333H938.666667v13.312C938.666667 811.306667 851.797333 896 704.682667 896H319.317333C172.202667 896 85.333333 811.306667 85.333333 668.245333V355.754667C85.333333 212.693333 172.202667 128 319.317333 128z m202.112 293.205333c17.621333 0 31.872 13.909333 31.872 31.061334v107.989333a31.829333 31.829333 0 0 1-31.872 31.061333H761.6c-42.410667 0.554667-79.530667-27.733333-89.130667-68.053333a84.565333 84.565333 0 0 1 18.474667-70.485333 89.258667 89.258667 0 0 1 67.242667-31.573334z m-128.170667 49.92h-14.037333a32.682667 32.682667 0 0 0-23.04 9.088 31.061333 31.061333 0 0 0-9.557334 22.357334c0 17.621333 14.506667 32 32.597334 32.170666h14.037333a32.213333 32.213333 0 0 0 32.597333-31.786666 32.213333 32.213333 0 0 0-32.597333-31.829334z m-250.325333-177.066666H287.488a32.213333 32.213333 0 0 0-32.64 31.402666c0 17.621333 14.549333 32 32.64 32.170667h240.810667a32.213333 32.213333 0 0 0 32.64-31.829333 32.213333 32.213333 0 0 0-32.64-31.786667z"
                fill="currentColor"></path>
            </svg>
            <span>Deposit from connected wallet</span>
            <div class="icon">
              <svg viewBox="0 0 1024 1024">
                <path
                  d="M512 611.872L226.752 318.592A48.48 48.48 0 0 0 192 304c-26.496 0-48 21.056-48 47.008 0 12.064 4.736 23.68 13.248 32.416l308.416 317.12q18.88 19.456 46.336 19.456 27.424 0 46.336-19.456l308.384-317.12c8.544-8.736 13.28-20.352 13.28-32.416 0-25.952-21.504-47.008-48-47.008-13.12 0-25.696 5.28-34.752 14.592L512 611.84z"
                  fill="currentColor"></path>
              </svg>
            </div>
          </div>
          <div class="wallet__content" v-if="!fold">
            <div class="label">
              <span>Wallet(SOL)</span>
              <span @click="setMax">Balance: <i> {{ solBalance }}</i></span>
            </div>
            <div class="balance">
              <div class="icon">
                <img src="@/assets/token/icon-sol.png" />
              </div>
              <span>SOL</span>
              <div class="input">
                <input type="text" v-model="amount" />
              </div>
            </div>
            <div class="button g-clickable" :class="{
              disable: isNaN(amount) || +amount === 0 || +amount > +maxBlance,
            }" @click="confirm">
              <span v-if="+amount > +maxBlance">Insufficient Amount</span>
              <span v-else>Deposit</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-qrcode :value="currentAccount" :color="{ dark: '#a1a1a1', light: '#000000' }" :margin="0" :quality="1" :scale="10" type="image/png" @change="onDataUrlChange" />
  </NouDialog>
</template>

<script>
import NouDialog from "@/components/common/NouDialog";
import VueQrcode from "vue-qrcode";
import { transferSOL } from "@/init";
import { getBalance } from "@/init";
import { mapState } from "vuex";
import { debounce } from "@/utils/helper";
import BigNumber from "bignumber.js";

export default {
  components: {
    NouDialog,
    VueQrcode,
  },
  data () {
    return {
      dataUrl: null,
      amount: "",
      fold: true,
      ids: 0
    };
  },
  computed: {
    ...mapState({
      wallet: (state) => state.wallet,
    }),
    maxBlance () {
      let b = new BigNumber(this.solBalance).minus(0.001).toNumber()
      return b < 0 ? 0 : b
    }
  },
  watch: {
    currentAccount () {
      this.load();
    },
  },
  mounted () {
    this.load();
  },
  methods: {
    async load () {
      if (!this.currentAccount) return;
      this.loadAuthAcountBalance();
      if (this.wallet !== "google") {
        this.loadAcountBalance();
      }
      clearInterval(this.ids);
      this.ids = setInterval(() => {
        this.loadAuthAcountBalance();
      }, 1000 * 10);
    },
    async loadAcountBalance () {
      await getBalance(
        this.account,
        "So11111111111111111111111111111111111111112"
      );
    },
    async loadAuthAcountBalance () {
      await getBalance(
        this.authAddress,
        "So11111111111111111111111111111111111111112"
      );
    },
    refresh: debounce(async function () {
      console.log('refresh');
      this.loadAuthAcountBalance();
    }, 500),
    setMax () {
      if (!this.maxBlance) return;
      this.amount = this.maxBlance
    },
    async confirm () {
      await transferSOL(this.currentAccount, this.amount);
    },
    onDataUrlChange (dataUrl) {
      this.dataUrl = dataUrl;
    },
    close () {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.deposit {
  &__title {
    padding: 16px 20px 16px 20px;
    margin: -20px -20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 20px;
      font-weight: 600;
    }

    .icon {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--border-color);

      i {
        font-size: 18px;
        font-weight: 600;
        color: var(--color-content);
        transition: all 0.3s ease;
      }

      &:hover {
        i {
          color: var(--color);
        }
      }
    }
  }

  &__content {
    .qrcode {
      padding: 20px;
      box-sizing: border-box;
      border-radius: 20px;
      background: #09090b;

      img {
        width: 100%;
      }
    }

    .address {
      margin-top: 16px;
      height: 45px;
      padding: 0 10px;
      border: 1px solid var(--border-color);
      box-sizing: border-box;
      border-radius: 10px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 12px;
        color: var(--color-content);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        width: 16px;
        color: var(--border-color);
      }
    }

    .label {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14px;
        color: var(--color-des);

        i {
          text-decoration: underline;
          color: var(--color-yellow);
          cursor: pointer;
        }
      }
    }

    .balance {
      margin-top: 10px;
      height: 45px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      border: 1px solid var(--border-color);
      box-sizing: border-box;
      border-radius: 10px;

      .icon {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;

        img {
          width: 28px;
          height: 28px;
          border-radius: 16px;
        }
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: var(--color);
      }

      .input {
        flex: 1;

        input {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
          box-sizing: border-box;
          text-align: right;

          font-size: 16px;
          line-height: 26px;
          font-weight: 600;
          padding: 3px 15px;
          box-sizing: border-box;
          color: var(--color);
          z-index: 2;
        }
      }

      .refresh {
        padding: 8px 16px;
        border-radius: 8px;
        background: var(--color-blue);

        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.auth {
        .input {
          input {
            text-align: left;
            color: var(--color-yellow);
          }
        }

        .button {
          margin-top: 0;
          height: 32px;
          border-radius: 8px;
          padding: 0 12px;

          span {
            font-size: 14px;
          }

          svg {
            height: 14px;
            margin-right: 8px;
          }
        }
      }
    }

    .button {
      margin: 20px 0 0;
      height: 45px;
      font-size: 18px;
      border-radius: 12px;
      background: var(--color-blue);

      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &.disable {
        background: var(--border-color);

        span {
          color: var(--color-des);
        }
      }

      span {
        font-size: 16px;
        font-weight: 500;
        color: var(--button-color);
        user-select: none;
      }
    }
  }
}

.wallet {
  margin-top: 10px;
  border: 1px solid var(--border-color);
  padding: 6px 10px;
  border-radius: 10px;

  &__title {
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;

    svg {
      width: 20px;
      margin-right: 5px;
      color: var(--color-content);
    }

    span {
      flex: 1;
      font-size: 14px;
      color: var(--color-content);
    }

    .slippage {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 6px;

      i {
        font-size: 16px;
        font-weight: 600;
        margin-left: 5px;
        color: var(--color-yellow);
      }
    }

    .icon {
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      svg {
        width: 16px;
        transition: all 0.3s ease;
        transform: rotate(180deg);
      }
    }
  }

  &.fold {
    .wallet__title {
      .icon {
        svg {
          transform: rotate(0deg);
        }
      }
    }

    .wallet__body {
      display: none;
    }
  }
}
</style>
