import axios from "axios";

const axiosInstance=axios.create();
axiosInstance.interceptors.request.use((config) => {
	if (config.method==='get') {
		// 添加时间戳到参数，避免coin98内置浏览器缓存
		const timestamp=Date.now();
		config.params={
			...config.params,
			_t: timestamp,
		};
	}
	return config;
}, (error) => {
	return Promise.reject(error);
});
const MarketApiURL = "https://pre-market-api.openocean.finance";
const SwapUrl = "https://ethapi.openocean.finance";

export const getCrossSwap = async (code, account, activeRoute) => {
  const { data } = await axiosInstance.post(`${MarketApiURL}/v1/cross/${code}/swap`, {
    account,
    route: JSON.parse(JSON.stringify(activeRoute)),
  });
  return {
    ...data.data,
  };
};

export const getSwap = async (chainId, params) => {
  let url = [];
  for (let key in params) {
    if (params[key]) {
      url.push(`${key}=${params[key]}`);
    }
  }
  if (chainId === "solana") {
    url.push("disabledDexIds = '1,2,7'")
    const { data } = await axiosInstance.get(
      `${SwapUrl}/v1/solana/swap-quote?${url.join("&")}`
    );
    return data;
  } else {
    const { data } = await axiosInstance.get(
      `${SwapUrl}/v2/${chainId}/swap?${url.join("&")}`
    );
    return data;
  }
};
