<template>
  <div class="empty" :style="compStyle">
    <svg viewBox="0 0 1024 1024">
      <path
        d="M642 82H162v860h700V302L642 82z m20 76.6L785.4 282H662V158.6zM822 902H202V122h420v200h200v580z"
        fill="currentColor"
      ></path>
    </svg>
    <span>{{ tip }}</span>
	<slot/>
  </div>
</template>
<script>

export default {
	props: {
		compStyle: {
			type: Object,
			default() {
				return {};
			},
		},
		tip: {
			type: String,
			default() {
				return 'No record';
			},
		},
  	},
}
</script>

<style lang="scss" scoped>
.empty {
  height: 100%;
  min-height: 160px;
  max-height: 240px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  svg {
    width: 56px;
    color: var(--border-color);
    margin-bottom: 10px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    color: var(--border-color);
  }
}
</style>