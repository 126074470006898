<template>
  <NouDialog
    @leave="closeModal"
    :dialogClass="{ wallet: true }"
  >
    <div class="creator">
      <div class="creator__inner" :class="{ show: showConfirm === true }">
        <div class="creator__box">
          <div class="creator__title">
            <span>Start a new Coin</span>
            <div class="creator__close g-clickable" @click="closeModal">
              <i
                class="iconfont icon-close nou-dialog__close nou-g-btn--hover"
              />
            </div>
          </div>
          <div class="creator__body">
            <SoarCreatorField
              v-model="name"
              name="Name"
              tips="Coin name, less than 32 characters"
            />
            <SoarCreatorField
              v-model="ticker"
              name="Ticker"
              tips="Coin ticker, less than 10 characters"
            />
            <SoarCreatorField
              v-model="description"
              name="Description"
              type="text"
              tips="Coin description, less than 2000 characters"
            />
            <SoarCreatorField
              v-model="image"
              name="Image"
              type="upload"
              tips="Coin image, less than 4.3 mega bytes"
              @fileChange="fileChange"
            />
            <div class="creator__more" :class="{ show: showMore === true }">
              <div class="g-clickable-small" @click="showMore = !showMore">
                <span>Show more options</span>
                <svg viewBox="0 0 1024 1024">
                  <path
                    d="M568.25 530.75l-112.5 0-1e-8-403.14375c0-25.875 20.98125-46.85625 46.85625001-46.85625l18.7875 0A46.85625 46.85625 0 0 1 568.25000001 127.60625L568.25 530.75z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M749.15 602.18750001l-194.06250001 314.71874999a46.85625 46.85625 0 0 1-79.48124999 0.50625l-199.29375-314.71874999A46.85625 46.85625 0 0 1 315.9125 530.75L709.26875 530.75a46.85625 46.85625 0 0 1 39.9375 71.4375z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </div>
            <div v-if="showMore">
              <SoarCreatorField v-model="twitter" name="Twitter (optional)" />
              <SoarCreatorField v-model="telegram" name="Telegram (optional)" />
              <SoarCreatorField v-model="website" name="Website (optional)" />
            </div>
          </div>
          <div
            class="creator__button g-clickable-small"
            @click="confirm"
          >
            <span>Create coin</span>
          </div>
        </div>
        <div class="creator__box">
          <div class="creator__title">
            <div class="creator__back g-clickable" @click="showConfirm = false">
              <svg viewBox="0 0 1024 1024">
                <path
                  d="M247.296 460.8l168.6016-168.6016A51.2 51.2 0 1 0 343.552 219.8016l-256 256a51.2 51.2 0 0 0 0 72.3968l256 256a51.2 51.2 0 1 0 72.3968-72.448L247.296 563.2h644.4032a51.2 51.2 0 1 0 0-102.4H247.296z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <span>Confirm</span>
            <div class="creator__close g-clickable" @click="closeModal">
              <i
                class="iconfont icon-close nou-dialog__close nou-g-btn--hover"
              />
            </div>
          </div>
          <div class="creator__tips">
            <span
              >Choose how many [{{ ticker }}] you want to buy (optional)</span
            >
            <span
              >tip: its optional but buying a small amount of coins helps
              protect your coin from snipers</span
            >
          </div>
          <div class="creator__field">
            <div class="creator__field__head">
              <div class="switch g-clickable" @click="isReverse = !isReverse">
                <span>Switch to</span>
                <span v-if="isReverse">SOL</span>
                <span v-else>{{ ticker }}</span>
              </div>
              <div class="balance" v-if="!isReverse">
                <span>Balance: </span>
                <span>{{ token.balance }}</span>
                <span>{{ token.symbol }}</span>
              </div>
            </div>
            <div class="creator__field__body" :class="{ focus: focus }">
              <div class="creator__field__input">
                <input
                  type="text"
                  @focus="focus = true"
                  @blur="focus = false"
                  v-model="amount"
                />
              </div>
              <div class="creator__field__token">
                <span>{{ token.symbol }}</span>
                <img :src="token.icon" />
              </div>
            </div>
          </div>
          <div class="creator__label" v-if="isReverse">
            <span>You cost: </span>
            <span>{{ tokenOut }}</span>
            <span>SOL</span>
          </div>
          <div class="creator__label" v-else>
            <span>You receive: </span>
            <span>{{ tokenOut }}</span>
            <span>{{ ticker }}</span>
          </div>
          <div class="creator__button g-clickable-small" :class="{ disabled: loading === true }" @click="create">
            <span class="g-loader" v-if="loading"></span>
            <span>Create coin</span>
          </div>
        </div>
      </div>
    </div>
  </NouDialog>
</template>

<script>
import NouDialog from "@/components/common/NouDialog";
import SoarCreatorField from "@/components/SoarCreatorField";
import iconSOL from "@/assets/token/icon-sol.svg";
import { ipfs } from "@/api";
import { getSolanaBalances } from "@/init";
import { launch, calc } from "@/swap";
import showToast from "@/components/toast";
import { debounce } from "@/utils/helper";

export default {
  components: {
    NouDialog,
    SoarCreatorField,
  },
  data() {
    return {
      name: "",
      ticker: "",
      image: "",
      twitter: "",
      telegram: "",
      website: "",
      description: "",
      fileSrc: "",
      amount: "",
      balance: 0,
      source: "pump",
      visible: true,
      showMore: false,
      focus: false,
      showConfirm: false,
      isReverse: false,
      loading: false
    };
  },
  computed: {
    token() {
      if (this.isReverse) {
        return {
          icon: this.fileSrc,
          symbol: this.ticker,
          balance: 0,
        };
      } else {
        return {
          icon: iconSOL,
          symbol: "SOL",
          balance: this.solBalance,
        };
      }
    },
    tokenOut() {
      if (!this.amount || +this.amount === 0) return 0;
      return calc(this.source, { amount: this.amount, isReverse: this.isReverse });
    },
  },
  watch: {
    currentAccount() {
      this.getBalance();
    },
  },
  mounted() {
    this.getBalance();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async getBalance() {
      if (!this.currentAccount) return;
      console.log('getBalance11111')
      const SOL = [{
        address: "So11111111111111111111111111111111111111112",
        decimals: 9
      }]
      await getSolanaBalances(SOL, this.currentAccount);
    },
    confirm: debounce(function () {
      if (!this.currentAccount) {
        showToast("Connect your wallet and sign in to create a coin");
        return;
      }
      if (!this.name) {
        showToast("Coin needs a name");
        return;
      }
      if (this.name.length > 32) {
        showToast("name too long: it must be less than 32 characters");
        return;
      }
      if (!this.ticker) {
        showToast("Coin needs a ticker");
        return;
      }
      if (this.ticker.length > 10) {
        showToast("ticker cannot be more than 10 characters");
        return;
      }
      if (!this.image) {
        showToast("no image uploaded");
        return;
      }
      if (this.image.size > 45e5) {
        showToast("image too large: it must be less than 4.3 megabytes");
        return;
      }
      if (this.description && this.description.length > 2e3) {
        showToast("description too long");
        return;
      }
      this.showConfirm = true;
    }, 500),
    async create() {
      if (this.loading) return;
      try {
        this.loading = true;
        const f = new FormData();
        f.append("file", this.image);
        f.append("name", this.name);
        f.append("symbol", this.ticker);
        f.append("description", this.description);
        f.append("twitter", this.twitter);
        f.append("telegram", this.telegram);
        f.append("website", this.website);
        f.append("showName", true);
        const ipfsData = await ipfs(f);
        if (!ipfsData) {
          showToast("upload ipfs fail");
          this.loading = false;
          return;
        }
        await launch(this.source, { account: this.currentAccount, ipfsData, amount: this.amount, isReverse: this.isReverse });
        this.loading = false;
      } catch(e) {
        this.loading = false;
      }
    },
    fileChange(val) {
      this.fileSrc = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.creator {
  overflow: hidden;
  &__inner {
    width: 720px;
    display: flex;
    transition: all 0.3s ease;
    &.show {
      transform: translateX(-50%);
    }
  }

  &__box {
    width: 360px;
    position: relative;
    z-index: 2;

    &:last-child {
      z-index: 1;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 18px;
      font-weight: 600;
    }
    padding: 16px 20px 16px 20px;
    margin: -20px -20px 0;
  }

  &__tips {
    display: flex;
    flex-direction: column;
    margin: 20px 20px 36px;

    span {
      font-size: 14px;
      color: var(--color-content);
      text-align: center;
      line-height: 1.5;
      &:first-child {
        font-size: 16px;
        font-weight: 600;
        color: var(--color);
        margin-bottom: 10px;
      }
    }
  }

  &__back {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--border-color);

    svg {
      height: 20px;
      color: var(--color-content);
      transition: all 0.3s ease;
    }

    &:hover {
      i {
        color: var(--color);
      }
    }
  }

  &__close {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--border-color);

    i {
      font-size: 26px;
      font-weight: 600;
      color: var(--color-content);
      transition: all 0.3s ease;
    }

    &:hover {
      i {
        color: var(--color);
      }
    }
  }

  &__more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -5px;
    margin-bottom: 5px;
    div {
      height: 36px;
      display: flex;
      padding: 0 10px;
      align-items: center;
      justify-content: center;

      span {
        font-size: 14px;
        color: var(--color);
      }

      svg {
        height: 14px;
        color: var(--color);
        transition: all 0.3s ease;
      }
    }
    &.show {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__button {
    height: 52px;
    font-size: 18px;
    color: var(--color);
    border-radius: 30px;
    margin-top: 5px;
    background: var(--color-green);

    &.disabled {
      background: var(--color-des);
    }

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      user-select: none;
    }

    .g-loader {
      width: 20px;
      height: 20px;
      border-color: var(--color);
      margin-right: 12px;
    }
  }

  &__field {
    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .switch {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
          font-size: 14px;
          color: var(--color-content);
          &:last-child {
            color: var(--color);
            margin-left: 6px;
          }
        }
      }

      .balance {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span {
          font-size: 14px;
          color: var(--color);
          margin-left: 6px;
          &:first-child {
            color: var(--color-content);
          }
        }
      }
    }

    &__body {
      background-color: var(--field);
      border: 1px solid var(--field);
      border-radius: 8px;
      transition: all 0.3s ease;

      &.focus {
        border-color: var(--color);
      }
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
    }

    &__input {
      flex: 1;
      input {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        text-align: left;

        font-size: 14px;
        line-height: 1;
        font-weight: 500;
        padding: 12px 15px;
        box-sizing: border-box;
        color: var(--color);
      }
    }

    &__token {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 10px;

      span {
        font-size: 16px;
        font-weight: 500;
      }

      img {
        width: 24px;
        margin-left: 6px;
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;

    span {
      font-size: 16px;
      color: var(--color);
      &:first-child {
        color: var(--color-content);
        margin-right: 6px;
      }
      &:nth-child(2) {
        font-weight: 600;
        margin-right: 5px;
      }
    }
  }
}
</style>
