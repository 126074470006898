import axios from 'axios';

const showError = () => { // todo
};

const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 2 * 60 * 1000,
});

service.interceptors.request.use((config) => {
	if (config.method==='get') {
		// 添加时间戳到参数，避免coin98内置浏览器缓存
		const timestamp=Date.now();
		config.params={
			...config.params,
			_t: timestamp,
		};
	}
	return config;
}, (error) => {
	return Promise.reject(error);
});

service.interceptors.response.use(
  (response) => {
    const { code } = response.data;
    if (code && !(code === 200)) { // ethapi has no code
      showError();
      return Promise.reject(response);
    }
    return response.data;
  },
  (error) => {
    showError();
    return Promise.reject(error);
  },
);

export default service;
