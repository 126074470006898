import axios from "axios";
import store from "@/store";
import { MEME_URL } from "@/utils/config";

const { state, getters } = store;
import { amount2Decimals, decimals2Amount } from "@/utils/number";

const axiosInstance = axios.create({
  baseURL: MEME_URL,
  timeout: 1000 * 120,
});
axiosInstance.interceptors.request.use((config) => {
    if (config.method === 'get') {
        // 添加时间戳到参数，避免coin98内置浏览器缓存
        const timestamp = Date.now();
        config.params = {
            ...config.params,
            _t: timestamp,
        };
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
axiosInstance.interceptors.response.use(
  (response) => {
    const { code } = response.data;
    if (code == 101) {
      window.$eventBus.$emit("showConnect", 101)
      return Promise.reject('Login expiration')
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const socialLogin = async (code) => {
  const { data } = await axiosInstance.post(`/account/socialLogin`, {
    code,
  });
  return data;
};

export const walletLogin = async (params) => {
  const { data } = await axiosInstance.post(`/account/walletLogin`, params);
  return data;
};

export const getServerTime = async () => {
  const { data } = await axiosInstance.get(`/account/time`);
  // {"code":0,"msg":"ok","data":1731985668050};
  return data && data.data;
};

// /account/referral/link
export const getReferralLink = async (account) => {
  const timestamp = await getServerTime();
  const { data } = await axiosInstance.get(`/account/referral/link?account=${account}`, {
    headers: {
      "api-key": state.authToken,
      timestamp: timestamp,
    },
  });
  return data && data.data || {};
};

// /account/referral/reward
export const getReferralReward = async (account) => {
  const timestamp = await getServerTime();
  const { data } = await axiosInstance.get(`/account/referral/reward?account=${account}`, {
    headers: {
      "api-key": state.authToken,
      timestamp: timestamp,
    },
  });
  return data && data.data || {};
};

// /account/referral/invest
export const setReferralInvest = async (code, investor, signature) => {
  const timestamp = await getServerTime();
  const { data } = await axiosInstance.post(`/account/referral/invest`, {
    code,
    investor,
    signature
  },
    {
      headers: {
        "api-key": state.authToken,
        timestamp: timestamp,
      },
    });
  return data || {};
};

// /account/referral/claim
export const getReferralClaim = async () => {
  const timestamp = await getServerTime();
  const { data } = await axiosInstance.post(`/account/referral/claim`,
    {},
    {
      headers: {
        "api-key": state.authToken,
        timestamp: timestamp,
      },
    });
  return data || {};
};

// /account/withdraw
export const withdraw = async (params) => {
  const timestamp = await getServerTime();
  const { data } = await axiosInstance.post(`/account/withdraw`, params, {
    headers: {
      "api-key": state.authToken,
      timestamp: timestamp,
    },
  });
  return data;
};

// /account/trade
export const trade = async (source, type, params) => {
  const { dex } = params;
  if (dex) {
    return await swap(type, params);
  }
  const timestamp = await getServerTime();
  const { data } = await axiosInstance.post(
    `/account/trade`,
    {
      source,
      type,
      data: params,
	  account: getters.currentAccount,
    },
    {
      headers: {
        "api-key": state.authToken,
        timestamp: timestamp,
      },
    }
  );
  return data
};

// /account/swap
export const swap = async (type, params) => {
  let inTokenSymbol = "";
  let inTokenAddress = "";
  let outTokenSymbol = "";
  let outTokenAddress = "";
  let amount = ''
  const { baseAsset, baseAmount, tokenAmount, quoteAsset, account, coinData, slippageDecimal } = params;
  if (type === 'buy') {
    inTokenSymbol = "SOL";
    inTokenAddress = "So11111111111111111111111111111111111111112";
    outTokenSymbol = coinData.symbol;
    outTokenAddress = coinData.mint;
    amount = amount2Decimals(baseAmount, baseAsset.decimals)
  } else {
    inTokenSymbol = coinData.symbol;
    inTokenAddress = coinData.mint;
    outTokenSymbol = "SOL";
    outTokenAddress = "So11111111111111111111111111111111111111112";
    amount = amount2Decimals(tokenAmount, quoteAsset.decimals)

  }
  const timestamp = await getServerTime();
  const { data } = await axiosInstance.post(
    `/account/swap`,
    {
      inTokenSymbol,
      inTokenAddress,
      outTokenSymbol,
      outTokenAddress,
      amount,
      // gasPrice,
      // disabledDexIds: "1,2",
      slippage: slippageDecimal * 10000,
      account,
      referrer: "",
      flags: 0,
	  account: getters.currentAccount,
    },
    {
      headers: {
        "api-key": state.authToken,
        timestamp: timestamp,
      },
    }
  );
  return data;
};

export const getTokenList = async () => {
  const timestamp = await getServerTime();
  const { data } = await axiosInstance.get(`/account/tokenList?account=${getters.currentAccount}`, {
    headers: {
      "api-key": state.authToken,
      timestamp: timestamp,
    },
  });
  return data && data.data;
};

export const getTradeList = async (page, limit) => {
  const timestamp = await getServerTime();
  const { data } = await axiosInstance.get(
    `/account/tradeList?page=${page}&limit=${limit}&account=${getters.currentAccount}`,
    {
      headers: {
        // "api-key": '1e6f653727b1421a8ca5104dfe0372da',
        "api-key": state.authToken,
        timestamp: timestamp,
      },
    }
  );
  const list = (data && data.data) || [];
  return list.filter(item => item.sol)

};



export const getQuote = async (params) => {
  const timestamp = await getServerTime();
  const { data } = await axiosInstance.post(
    `/account/quote`,
    params,
    {
      headers: {
        "api-key": state.authToken,
        timestamp: timestamp,
      },
    }
  );
  return decimals2Amount(Number(data.data), params.outTokenDecimals || 18)
};



export const tradingSetting = async (params) => {
  // const timestamp = await getServerTime();
  const { data } = await axiosInstance.post(
    `/trading/follow/setting`,
    params,
    {
      headers: {
        "api-key": state.authToken,
        // timestamp: timestamp,
      },
    }
  );
  return data
};


export const tradingCancel = async (masterAccount) => {
  const { data } = await axiosInstance.get(`/trading/follow/cancel?masterAccount=${masterAccount}`, {
    headers: {
      "api-key": state.authToken,
    },
  });
  return data && data.data;
};


export const tradingGet = async (masterAccount) => {
  const { data } = await axiosInstance.get(`/trading/follow/get?masterAccount=${masterAccount}`, {
    headers: {
      "api-key": state.authToken,
    },
  });
  return data && data.data;
};

export const tradingStats = async () => {
  // const timestamp = await getServerTime();
  const { data } = await axiosInstance.get(`/trading/follow/stats`, {
    headers: {
      "api-key": state.authToken,
      // timestamp: timestamp,
    },
  });
  return data && data.data;
};

