import Web3 from "web3"
import CryptoJS from "crypto-js";
import { PublicKey } from "@solana/web3.js";

export const isNativeToken = (address) => {
  return (
    [
      "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      "0x0000000000000000000000000000000000000000",
      "0x0000000000000000000000000000000000001010",
    ].indexOf(address) >= 0
  );
};
export function isSolanaAddress (address) {
  try {
    new PublicKey(address);
    return true;
  } catch (e) {
    return false;
  }
}

export function sleep (interval) {
  return new Promise((resolve) => {
    setTimeout(resolve, interval);
  });
}

export function debounce (fn, delay) {
  var delay = delay || 200;
  var timer;
  return function () {
    var th = this;
    var args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      timer = null;
      fn.apply(th, args);
    }, delay);
  };
}

export function throttle (fn, interval) {
  var last;
  var timer;
  var interval = interval || 200;
  return function () {
    var th = this;
    var args = arguments;
    var now = +new Date();
    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        fn.apply(th, args);
      }, interval);
    } else {
      last = now;
      fn.apply(th, args);
    }
  };
}

export const formatWithCommas = (number) => {
  var parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const fnOpenUrl = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function confirmLater (promise) {
  return new Promise((resolve, reject) => {
    promise.on('transactionHash', resolve);
    promise.on('error', reject);
    function onConfirm (confNumber, receipt) {
      promise.off('confirmation', onConfirm);
    }
    promise.on('confirmation', onConfirm);
  });
}

export function isApp () {
  // var isIosApp = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
  //   navigator.userAgent
  // );
  // var isAndroidApp = window.android ? 1 : 0;
  // return isIosApp || isAndroidApp || Web3.givenProvider;
  return (!!Web3.givenProvider || !!window.safepal_wallet_app) && isMobile()
}

export function isMobile () {
  const ua = navigator.userAgent.toLowerCase();
  const testUa = regexp => regexp.test(ua);

  return testUa(/android|adr/g) || testUa(/ios|iphone|ipad|ipod/g) || testUa(/mobile/g) || window.android || testUa(/miui/g)
}

// export function encrypt(text) {
//   const key = crypto.scryptSync('', 'salt', 32);
//   const iv = '654de3b6def24f19957faf493043553e';
//   const cipher = crypto.createCipheriv('aes-256-cbc', key, Buffer.from(iv, 'hex'));
//   let encrypted = cipher.update(text, 'utf8', 'hex');
//   encrypted += cipher.final('hex');
//   return encrypted;
// }

export function encrypt (text) {
  const key = CryptoJS.enc.Hex.parse('d0464f51a6f78120f9c19bfae4932fc09f2f88443774b15c4ba71f4dcaef28a5');
  const iv = CryptoJS.enc.Hex.parse('654de3b6def24f19957faf493043553e');
  const message = CryptoJS.enc.Utf8.parse(text);
  const encrypted = CryptoJS.AES.encrypt(message, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.ciphertext.toString();
}
export function isMobileBrowser() {
	const userAgent=navigator.userAgent||navigator.vendor||window.opera;
	const isMobile=/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
	return isMobile;
}
export function getAppWalletCode() {
	const userAgent=navigator.userAgent||navigator.vendor;
	let walletCode='';
	if (window.phantom) {
		const isPhantomInjected=window.phantom?.solana?.isPhantom;
		const includesPhantom=userAgent.includes("Phantom")||userAgent.includes("phantom")
		walletCode=isPhantomInjected&&includesPhantom? 'phantom':'';
	} else if (window.coin98) {
		const isCoin98Injected=window.coin98?.sol?.isCoin98;
		const includesCoin98=userAgent.includes("Coin98")||userAgent.includes("Coin98");
		walletCode=isCoin98Injected&&includesCoin98? 'coin98':''
	}
	
	return walletCode
}